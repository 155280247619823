<template>
  <div id="features_container">
    <div :class="['plan_ul', isFeatures ? 'features' : '']">
      <el-tabs v-model="activeName" @tab-click="tabChange">
        <el-tab-pane v-for="(item, index) in FEATURES_DESC" :label="item.tabName" :name="item.id" :key="index">
          <template v-slot:label>
            <div class="img_box">
              <img :src="item.icon" />
              <p class="img_text">{{ item.tabName }}</p>
            </div>
          </template>
          <div class="plan_desc">
            <div class="tabs_list tab_list_one">
              <div class="tab_desc_text tab_desc_inline">
                <div class="desc_text_title">{{ item.children[0].title }}</div>
                <p class="desc_text_p">
                  {{ item.children[0].text[0] }}
                </p>
                <!--<el-button class="regis_btn" type="primary" @click="$router.push({ name: 'REGISTER' })">
                  立即试用
                </el-button>-->
              </div>
              <div class="tab_desc_img tab_desc_inline wow slideInRight" data-wow-duration="1s">
                <img :src="item.children[0].imgUrl" />
              </div>
            </div>
            <div class="tabs_list tab_list_two">
              <div class="tab_desc_img tab_desc_inline wow slideInLeft" data-wow-duration="1s">
                <img :src="item.children[1].imgUrl" />
              </div>
              <div class="tab_desc_text tab_desc_inline">
                <div class="desc_text_title">{{ item.children[1].title }}</div>
                <p class="desc_text_p">
                  {{ item.children[1].text[0] }}
                </p>
                <!--<el-button class="regis_btn right" type="primary" @click="$router.push({ name: 'REGISTER' })">
                  立即试用
                </el-button>-->
              </div>
            </div>
            <div class="tabs_list tab_list_three">
              <div class="tab_desc_text tab_desc_inline">
                <div class="desc_text_title">{{ item.children[2].title }}</div>
                <p class="desc_text_p">
                  {{ item.children[2].text[0] }}
                </p>
                <!--<el-button class="regis_btn" type="primary" @click="$router.push({ name: 'REGISTER' })">
                  立即试用
                </el-button>-->
              </div>
              <div class="tab_desc_img tab_desc_inline wow slideInRight" data-wow-duration="1s">
                <img :src="item.children[2].imgUrl" />
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import { WOW } from 'wowjs';
import FEATURES_DESC_JSON from './featuresDesc.js';
const FEATURES_DESC = cloneDeep(FEATURES_DESC_JSON);
FEATURES_DESC.forEach(i => {
  i.icon = require(`@/assets/images/pcHome/${i.icon}`);
  i.children.forEach(item => {
    item.imgUrl = require(`@/assets/images/pcHome/${item.imgUrl}`);
  });
});

export default {
  props: {
    isFeatures: Boolean,
    tabName: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      wowInstance: null,
      FEATURES_DESC,
      activeName: 'first_inner',
      onePx: 1,
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.wowInstance = new WOW({
        boxClass: 'wow', // default
        animateClass: 'animated', // default
        offset: 0, // default
        mobile: true, // default
        live: true, // default
      });
      this.wowInstance.init();
    });
  },
  methods: {
    tabChange () {
      this.$nextTick(() => {
        this.scrollHandle();
      });
    },
    // 出来图片动画滚动才生效
    scrollHandle () {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (document.documentElement.scrollTop) {
        document.documentElement.scrollTop = scrollTop + this.onePx;
      }
      if (document.body.scrollTop) {
        document.body.scrollTop = scrollTop + this.onePx;
      }
      this.onePx = -Number(this.onePx);
    },
  },
  beforeDestroy () {
    this.wowInstance = null;
  },
  watch: {
    tabName: {
      immediate: true,
      handler (val) {
        this.scrollHandle();
        this.activeName = val;
      },
    },
  },
};
</script>
<style lang="less" scoped>
.plan_box {
  background: #fff;
  padding-top: 80px;
  .title_box {
    text-align: center;
    margin-bottom: 48px;
    h2 {
      color: #222426;
      font-size: 26px;
      margin-bottom: 11px;
      font-weight: 400;
    }
    h4 {
      color: #7c848e;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .plan_ul {
    // width: 1200px;
    /deep/ .el-tabs__nav-wrap.is-top {
      padding: 0 32px;
    }
    /deep/ .el-tabs__nav-wrap::after {
      background: transparent;
    }
    /deep/ .el-tabs__header {
      width: 1200px;
      margin: 0 auto;

      .img_box {
        overflow: hidden;
        text-align: center;
        img {
          margin: auto;
          display: block;
          width: 70px;
          height: 50px;
        }
        .img_text {
          color: #4a4e51;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .is-active {
        .img_text {
          color: #0f89ff;
          font-weight: 500;
        }
      }
      .el-tabs__nav {
        display: flex;
        float: none;
        .el-tabs__item {
          flex: 1;
          height: auto;
          padding-right: 30px;
        }
      }
    }
    /deep/ .el-tabs__content {
      background: url('../../assets/images/pcHome/desc01.png'), url('../../assets/images/pcHome/desc02.png'),
        linear-gradient(#f6f8ff 0%, #f6f8ff, 33.3%, #ffffff 33.3%, #ffffff 66.7%, #f6f8ff 66.7%, #f6f8ff 100%);
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position: right 100px, left 500px, center center;
      background-size: 19%, 18%, 100%;
      height: 780px;
      overflow: hidden;
      .plan_desc {
        width: 1200px;
        margin: auto;
        display: flex;
        flex-direction: column;
        .tabs_list {
          flex: 1;
          .tab_desc_text {
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            padding: 58px 38px 0 32px;
            width: 640px;
            overflow: hidden;
          }
          .tab_desc_img {
            display: inline-block;
            vertical-align: top;
            width: 560px;
            overflow: hidden;
            img {
              display: block;
              width: 100%;
            }
          }
          .desc_text_title {
            color: #242832;
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 24px;
          }
          .desc_text_p {
            color: #4a4e51;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 1px;
          }
          .regis_btn {
            margin-top: 24px;
            width: 96px;
            height: 42px;
            background: #0f89ff;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            box-sizing: border-box;
            &.right {
              float: right;
            }
          }
        }
      }
      &::before {
        content: ' ';
        display: block;
        width: 100%;
        height: 1px;
        box-shadow: 0px 8px 12px rgba(0, 58, 114, 0.6);
      }
    }
    /deep/ .el-tab-pane {
      display: flex;
      width: 1200px;
      margin: auto;
      height: 100%;
    }
  }
}
.plan_ul.features {
  /deep/ .el-tabs__nav-wrap.is-top {
    padding: 0 28px;
  }
  /deep/ .el-tabs__content {
    height: 1200px;
    .plan_desc {
      height: 100%;
      .tabs_list {
        display: flex;
        align-items: center;
        .tab_desc_text {
          width: 556px;
          // padding: 133px 0 0 28px;
        }
        .tab_desc_img {
          width: 644px;
          position: relative;
          line-height: 400px;
          img {
            margin: auto;
            width: 616px;
            height: 286px;
          }
        }
        .desc_text_title {
          font-size: 24px;
        }
        .desc_text_p {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
