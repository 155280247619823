<template>
  <div id="features_container">
    <HOMEHEADER
      ref="headerRef"
      :isPC="isPC"
      @goAnchor="goAnchor"
      :scrollOptical="495"
      sourceType="offical"
      @go-position="goPosition"
    />
    <div class="top_box">
      <div class="text_box">
        <p>国内领先的saas服务平台</p>
        <p>帮助企业走向数字化转型新时代</p>
        <!--<el-button class="tryBtn" type="primary" @click="$router.push({ name: 'REGISTER' })">立即试用</el-button>-->
      </div>
    </div>
    <div id="features" class="plan_box wow fadeInUp" data-wow-duration="2s">
      <div class="title_box">
        <h2>功能介绍</h2>
        <h4>开启医疗设备维修保养新体验</h4>
      </div>
      <featuresComponent v-if="isPC" :isFeatures="true" :tabName="tabName"></featuresComponent>
    </div>
    <footerNav v-if="isPC" @change-tab="changeTab" />
    <div class="footer_box">
      <footerBox />
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import HOMEHEADER from '../HomeComponents/homeHeader.vue';
import featuresComponent from '../HomeComponents/featuresComponent.vue';
import footerNav from '../HomeComponents/footerNav.vue';
import footerBox from '../HomeComponents/footerBox.vue';
import { WOW } from 'wowjs';

export default {
  components: {
    HOMEHEADER,
    featuresComponent,
    footerBox,
    footerNav,
  },
  data () {
    return {
      isPC: true,
      tabName: 'first_inner',
      currentPageW: document.documentElement.clientWidth || document.body.clientWidth,
    };
  },
  created () {
    window.addEventListener('resize', _.debounce(this.resizeHandle, 100), true);
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.headerRef.resetActive(1);
      new WOW({
        boxClass: 'wow', // default
        animateClass: 'animated', // default
        offset: 0, // default
        mobile: true, // default
        live: true, // default
      }).init();
      if (this.$route.query?.tabName) {
        this.tabName = this.$route.query?.tabName;
        this.goAnchor('#features');
      }
    });
  },
  methods: {
    goPosition (tabName) {
      this.tabName = tabName;
      this.goAnchor('#features');
    },
    resizeHandle () {
      this.currentPageW = document.documentElement.clientWidth || document.body.clientWidth;
    },
    goAnchor (id) {
      this.$nextTick(() => {
        document.querySelector(id)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      });
    },
    changeTab (tabName) {
      this.tabName = tabName;
    },
  },
  watch: {
    currentPageW: {
      immediate: true,
      handler (val) {
        // 可视区域大于1200视为PC样式
        this.isPC = val > 1200;
      },
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeHandle, true);
  },
};
</script>
<style lang="scss" scoped>
.plan_box {
  background: #fff;
  padding-top: 80px;
  .title_box {
    text-align: center;
    margin-bottom: 48px;
    h2 {
      color: #222426;
      font-size: 26px;
      margin-bottom: 11px;
      font-weight: 400;
    }
    h4 {
      color: #7c848e;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
.top_box {
  height: 400px;
  background: url('../../assets/images/about/bannerPC.png');
  background-size: 100% 100%;
  color: #fff;
  overflow: hidden;
  .text_box {
    text-align: center;
    margin-top: 175px;
    p {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 1px;
    }
    .tryBtn {
      width: 138px;
      border-radius: 4px;
      margin-top: 36px;
    }
  }
}
.footer_box {
  text-align: center;
  background: #1f1f1f;
  color: #a0aab1;
  font-size: 16px;
}
</style>
